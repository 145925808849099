import React from 'react';
import Head from 'next/head';
import type { NextPage } from 'next';

import { HomeHolding } from '@/components/pages';
import generateMetaTags from '@/utils/meta';

const Index: NextPage = () => {
  return (
    <>
      <Head>{generateMetaTags()}</Head>
      <HomeHolding />
    </>
  );
};

export default Index;
